import React from 'react';
import styled from 'styled-components';
import { CardGrid } from '@naf/card-grid';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { GridCol } from '@naf/grid';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { ArticleReference } from '../../../../../../types/articleReferenceType';
import { useDocumentUrlWithNode } from '../../../../hooks/useDocumentUrl';
import { StyledLink } from '../../../styled-link/StyledLink';
import { useCloudinary } from '../../../../hooks/useCloudinary';
import useSelector from '../../../../redux/typedHooks';

interface Props {
  article: ArticleReference;
}

export const NewsCard = ({ article }: Props) => {
  const cld = useCloudinary();

  const cldImage =
    article.image &&
    (article.image.public_id || article.image.publicId) &&
    cld
      .image(article.image.public_id || article.image.publicId)
      .resize(fill().width(768).height(432))
      .quality('auto')
      .format('auto');

  const itemUrl = useDocumentUrlWithNode(article) || '';
  const isMember = useSelector((state) => state?.myMembership?.customerInformation?.data?.membership?.isValid);

  return (
    <StyledCol s="12" m="6" l="6" xl="4">
      <StyledLink to={itemUrl} $hasCardGrid>
        <CardGrid
          headerTag="h2"
          title={article.name}
          memberLabel={
            article.membersOnly ? { labelText: 'For medlemmer', isEligible: isMember, inverted: true } : undefined
          }
          advancedImage={
            cldImage && (
              <AdvancedImage
                alt={article.image?.altText || ''}
                cldImg={cldImage}
                plugins={[lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 })]}
              />
            )
          }
          img={{
            src: article.image?.srcSet?.jpgLarge || '',
            alt: article.image?.altText || '',
          }}
        >
          {article?.ingress}
        </CardGrid>
      </StyledLink>
    </StyledCol>
  );
};

const StyledCol = styled(GridCol)`
  display: flex;
`;
